<template>
  <a-modal
      :visible="visible"
      title="Saring Data Kandidat Murid"
      centered
      :width="800"
      @cancel="cancel"
    >
      <template slot="footer">
          <a-button key="back" size="large" @click.prevent="cancel">Batal</a-button>
          <a-button
            key="submit"
            type="primary" size="large"
            @click.prevent="save"
          >
            Saring
          </a-button>
        </template>
      <div>
        <a-row :gutter="[16, 16]" type="flex">
          <a-col :lg="12" :md="24">
            <a-form-item label="Jenis Kelamin" :colon="false">
              <a-select v-model="filter.jenis_kelamin" class="w-100" size="large" placeholder="Pilih Jenis Kelamin">
                <a-select-option value="">Semua</a-select-option>
                <a-select-option value="Laki-Laki">Laki-Laki</a-select-option>
                <a-select-option value="Perempuan">Perempuan</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="24">
            <a-form-item label="Agama" :colon="false">
              <a-select v-model="filter.agama" class="w-100" size="large" placeholder="Pilih Agama">
                <a-select-option value="">Semua</a-select-option>
                <a-select-option v-for="religion in religions" :key="religion.key" :value="religion.name">{{religion.title}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="24">
            <a-form-item label="Kewarganegaraan" :colon="false">
              <a-select v-model="filter.kewarganegaraan" class="w-100" size="large" placeholder="Pilih Kewarganegaraan">
                <a-select-option value="">Semua</a-select-option>
                <a-select-option value="WNI">WNI</a-select-option>
                <a-select-option value="WNA">WNA</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="24">
            <a-form-item label="Jarak Rumah Ke Sekolah" :colon="false">
              <a-select v-model="filter.geolokasi_tempat_tinggal" class="w-100" size="large" placeholder="Pilih Jarak Dari Rumah">
                <a-select-option value="">Semua</a-select-option>
                <a-select-option v-for="distance in distances" :key="distance.key" :value="distance.title">{{distance.title}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="24">
            <a-form-item label="Jenis Bantuan" :colon="false">
              <a-select v-model="filter.jenis_bantuan" class="w-100" size="large" placeholder="Pilih Jenis Bantuan">
                <a-select-option value="">Semua</a-select-option>
                <a-select-option value="kps_pkh">Pemegang KPS/PKH</a-select-option>
                <a-select-option value="kip">Pemegang KIP</a-select-option>
                <a-select-option value="pip">Pemegang PIP</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="24">
            <a-form-item label="Riwayat Pencapaian Siswa" :colon="false">
              <a-select v-model="filter.pencapaian" class="w-100" size="large" placeholder="Pilih Pencapaian Siswa">
                <a-select-option value="">Semua</a-select-option>
                <a-select-option value="prestasi">Berprestasi</a-select-option>
                <a-select-option value="beasiswa">Memiliki Beasiswa</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Berkebutuhan Khusus" :colon="false">
              <!-- <a-select mode="multiple" v-model="filter.berkebutuhan_khusus" class="w-100" size="large" show-search placeholder="Pilih Kategori Kebutuhan Khusus">
                <a-select-option v-for="disabled in disabledList" :key="disabled.key" :value="disabled.name">{{disabled.title}}</a-select-option>
              </a-select> -->
              <a-switch checked-children="Ya" v-model="filter.berkebutuhan_khusus" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="Rentang Penghasilan Orangtua" :colon="false">
              <a-select v-model="filter.rentang_penghasilan" class="w-100" size="large" placeholder="Pilih Rentang Penghasilan">
                <a-select-option value="">Semua</a-select-option>
                <a-select-option v-for="revenue in revenues" :key="revenue.key" :value="revenue.name">{{revenue.title}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
    </a-modal>
</template>

<script>
import { religions, jarakTempuhList, waktuTempuhList, monthlyRevenueList, disabledList } from '@/helpers/listsPpdbSelect'

export default {
  name: 'FilterDetailStudentCandidate',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    filterData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      this.$emit('save', this.filter)
    },
  },
  data() {
    return {
      filter: {
        agama: '',
        kewarganegaraan: '',
        berkebutuhan_khusus: false,
        // berkebutuhan_khusus: [],
        geolokasi_tempat_tinggal: '',
        rentang_penghasilan: '',
        jenis_bantuan: '',
        pencapaian: '',
        jenis_kelamin: '',
      },
      religions,
      distances: jarakTempuhList,
      estimatedTimes: waktuTempuhList,
      revenues: monthlyRevenueList,
      disabledList,
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.filter = { ...this.filterData }
      }
    },
  },
  mounted() {
    this.filter = { ...this.filterData }
  },
}
</script>

<style>

</style>
